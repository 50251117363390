import type { WhatsNextProps } from './index';

import React from 'react';

import type useClassy from '@core/hooks/useClassy';

import RDMD from '@ui/RDMD';
import Textarea from '@ui/Textarea';

interface Props {
  bem: ReturnType<typeof useClassy>;
  description: WhatsNextProps['description'];
  isEditable: WhatsNextProps['isEditable'];
  onChange: WhatsNextProps['onChange'];
}

function Description({ isEditable, bem, onChange, description }: Props) {
  return isEditable ? (
    <Textarea
      autoGrow
      className={bem('-input', '-description')}
      isMinimal
      onChange={onChange}
      placeholder="Tell your users what they should do after they've finished this page"
      resize="none"
      rows={1}
      value={description || ''}
    />
  ) : (
    <RDMD className={bem('-description', !description && '-description_empty')} Tag="div">
      {description}
    </RDMD>
  );
}

export default Description;
