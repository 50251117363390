import React from 'react';

import type useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import type { LinkEditorProps } from '@ui/LinkEditor';
import LinkEditor from '@ui/LinkEditor';

interface Props {
  baseUrl?: string;
  bem: ReturnType<typeof useClassy>;
  eventId?: string;
  isCreate?: boolean;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSubmit: LinkEditorProps['onSubmit'];
  url?: string;
}

function LinkMenu({ isCreate = false, url, label, onClick, baseUrl, bem, onSubmit }: Props) {
  const footer = (
    <Button className={bem('-submitBtn')} onClick={onClick} size="sm" type="submit">
      {isCreate ? 'Add link' : 'Update'}
    </Button>
  );

  return <LinkEditor baseUrl={baseUrl} footer={footer} label={label} onSubmit={onSubmit} required url={url} />;
}

export default LinkMenu;
