import type { NextPageLink, PageClientSide } from '@readme/backend/models/page/types';

import { addToArray, removeAtIndex, replaceAtIndex } from '@readme/iso';
import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import classy from '@core/utils/classy';

import SectionHeader from '@ui/API/SectionHeader';
import Button from '@ui/Button';
import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import Description from './Description';
import LinkMenu from './LinkMenu';
import PageMenu from './PageMenu';
import classes from './style.module.scss';

export enum Operation {
  Add,
  Update,
  Delete,
}

export interface WhatsNextProps {
  /**
   * A base url that internal pages can use
   */
  baseUrl?: string;
  /**
   * An className to apply to the root element
   */
  className?: string;
  /**
   * Main body content for that describes what's next
   */
  description?: PageClientSide['next']['description'];
  /**
   * All internal pages for the dropdown to render
   */
  internalPages?: NextPageLink[];
  /**
   * Flag to indicate whether you want allow the user to edit the description and pages fields
   */
  isEditable?: boolean;
  /**
   * Define a custom header
   */
  label?: string;
  /**
   * Propagates changes to the next description
   */
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  /**
   * Propagates changes to the next pages
   */
  onPagesUpdate?: (nextPages: NextPageLink[]) => void;
  /**
   * Existing page and links to be displayed under what's next
   */
  pages?: NextPageLink[];
}

const WhatsNext = ({
  baseUrl,
  className,
  description,
  internalPages = [],
  isEditable = false,
  label,
  onChange,
  onPagesUpdate,
  pages,
}: WhatsNextProps) => {
  const bem = useClassy(classes, 'WhatsNext');
  const dropdownRefs = useRef<Record<string, DropdownRef>>({});

  const pageToPath = ({ category, type, slug }: Pick<NextPageLink, 'category' | 'slug' | 'type'>) => {
    switch (type) {
      case 'doc':
        return `../docs/${slug}`;
      case 'ref':
        if (category === 'Developer Dashboard') {
          return `../${isEditable ? 'refs' : 'reference/intro'}/${slug}`;
        }

        return `../${isEditable ? 'refs' : 'reference'}/${slug}`;

      default:
        return '../';
    }
  };

  const updatePages = useCallback(
    ({ operation, idx, nextPage }: { idx?: number; nextPage?: NextPageLink; operation: Operation }) => {
      if (nextPage && (operation === Operation.Add || idx == null)) {
        onPagesUpdate?.(addToArray<NextPageLink>(pages || [], nextPage, (pages || [])?.length));
        return;
      }

      if (operation === Operation.Delete) {
        onPagesUpdate?.(removeAtIndex<NextPageLink>(pages || [], idx as number));
        return;
      }

      // Assume it's an update
      if (nextPage) {
        onPagesUpdate?.(replaceAtIndex<NextPageLink>(pages || [], nextPage, idx as number));
      }
    },
    [onPagesUpdate, pages],
  );

  return (
    <>
      <div className={classy(bem('-wrapper'), className)}>
        <SectionHeader className={classy(bem('-heading'))} heading={label || 'What’s Next'}></SectionHeader>
        <Description bem={bem} description={description} isEditable={isEditable} onChange={onChange} />
        {/* Rows of existing pages/links */}
        <ul>
          {pages?.map((page, idx) => {
            const { name, type, slug, value, category } = page;
            const key = `${slug || value}-${idx}`;

            return (
              <li key={key}>
                {type === 'url' ? (
                  <a className={bem('-link')} href={value} target="blank">
                    {name}
                  </a>
                ) : (
                  <Link className={bem('-link')} target="_self" to={pageToPath({ type, slug, category })}>
                    {name}
                  </Link>
                )}
                <Icon className="icon" name={type === 'url' ? 'arrow-up-right' : 'arrow-right'} />
                {/* Buttons next to each page/link, when editable, to edit or delete it */}
                {!!isEditable && (
                  <div className={bem('-editIcons')}>
                    <Dropdown
                      ref={(dropdown: DropdownRef) => {
                        dropdownRefs.current[key] = dropdown;
                      }}
                      justify="start"
                      sticky
                    >
                      <Button aria-label="edit" is="button" kind="secondary" size="xs" text>
                        <Icon name="edit-2" title="edit" />
                      </Button>
                      {type === 'url' ? (
                        <LinkMenu
                          baseUrl={baseUrl}
                          bem={bem}
                          label={page.name}
                          onClick={() => {
                            dropdownRefs.current[key]?.toggle();
                          }}
                          onSubmit={(_, { url, label: nextLabel }) => {
                            const nextPage = { type: 'url', value: url, name: nextLabel } as NextPageLink;
                            updatePages({ nextPage, operation: Operation.Update, idx });
                          }}
                          url={page.value}
                        />
                      ) : (
                        <PageMenu
                          bem={bem}
                          onClick={(result: NextPageLink) => {
                            updatePages({ nextPage: result, operation: Operation.Update, idx });
                            dropdownRefs.current[key]?.toggle();
                          }}
                          pages={internalPages}
                        />
                      )}
                    </Dropdown>
                    <Button
                      aria-label="delete"
                      is="button"
                      kind="destructive"
                      onClick={() => updatePages({ operation: Operation.Delete, idx })}
                      size="xs"
                      text
                    >
                      <Icon name="x" title="delete" />
                    </Button>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
        {/* Buttons at the bottom, when editable, to add a new page/link */}
        {!!isEditable && (
          <Flex className={bem('-menus')} gap="sm" justify="start">
            <Dropdown
              ref={(dropdown: DropdownRef) => {
                dropdownRefs.current.addNewPage = dropdown;
              }}
              justify="start"
              sticky
            >
              <Button kind="secondary" outline size="sm">
                <Icon name="plus" /> Page
              </Button>
              <PageMenu
                bem={bem}
                onClick={(result: NextPageLink) => {
                  updatePages({ nextPage: result, operation: Operation.Add });
                  dropdownRefs.current.addNewPage?.toggle();
                }}
                pages={internalPages}
              />
            </Dropdown>
            <Dropdown
              ref={(dropdown: DropdownRef) => {
                dropdownRefs.current.addNewLink = dropdown;
              }}
              justify="start"
              sticky
            >
              <Button kind="secondary" outline size="sm">
                <Icon name="plus" /> External Link
              </Button>
              <LinkMenu
                baseUrl={baseUrl}
                bem={bem}
                eventId="newLink"
                isCreate
                onSubmit={(_, { url, label: nextLabel }) => {
                  const nextPage = { type: 'url', value: url, name: nextLabel } as NextPageLink;
                  updatePages({ nextPage, operation: Operation.Add });
                  dropdownRefs.current.addNewLink?.toggle();
                }}
              />
            </Dropdown>
          </Flex>
        )}
      </div>
    </>
  );
};

export default React.memo(WhatsNext);
