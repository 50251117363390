import React from 'react';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Textarea from '@ui/Textarea';

interface Props {
  comment: string;
  setComment: (comment: string) => void;
  submitVote: () => void;
}

const CommentBox = ({ comment, setComment, submitVote }: Props) => {
  return (
    <Box className="PageThumbs-comment_box" kind="card">
      <div className="PageThumbs-comment-body">
        <label>
          Leave an optional comment…
          <Textarea className="PageThumbs-comment_input" onChange={e => setComment(e.target.value)} value={comment} />
        </label>
      </div>
      <footer className="PageThumbs-comment-footer">
        <Button className="PageThumbs-comment_submit" onClick={submitVote} size="sm">
          Vote
        </Button>
      </footer>
    </Box>
  );
};

export default CommentBox;
