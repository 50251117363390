import React, { useState } from 'react';

import useIsDocumentReady from '@core/hooks/useIsDocumentReady';
import useLocalStorage from '@core/hooks/useLocalStorage';
import { safelyParseJSON, safelyStringifyJSON } from '@core/utils/json';

import ThumbWithDropdown from './components/ThumbWithDropdown';
import './style.scss';

interface VoteData {
  comments: string;
  email?: string;
  path: string;
  type: string;
  vote: number;
}

interface Props {
  email?: string;
  pathname: string;
  projectSubdomain: string;
  sendToMetrics: (data: VoteData) => void;
}

const PageThumbs = ({ pathname, projectSubdomain, sendToMetrics }: Props) => {
  const storage = useLocalStorage();
  const isDocumentReady = useIsDocumentReady();

  const initialVotes = safelyParseJSON(storage.getItem('helpfulVotes')) || {};

  /**
   * Either use stored project votes or create new empty project votes
   * Votes are stored on project ID objects
   * Ex: { 'domain-1': { 'pathname/1': 1, 'pathname/2': 1 }, 'domain-2': { 'pathname/1': 0 } }
   * */
  const projectVotes = initialVotes[projectSubdomain] || {
    [projectSubdomain]: {},
  };

  const [hasVoted, setHasVoted] = useState(projectVotes[pathname] !== undefined);
  const [vote, setVote] = useState<number | null>(null);
  const [comment, setComment] = useState('');

  const handleVote = (voteYes: boolean) => {
    setVote(voteYes ? 1 : 0);
  };

  const submitVote = () => {
    if (vote === null) return;

    setHasVoted(true);
    setComment('');

    const newStoredVotes = {
      ...initialVotes,
      [projectSubdomain]: {
        ...initialVotes[projectSubdomain],
        [pathname]: vote,
      },
    };

    const jsonResult = safelyStringifyJSON(newStoredVotes);

    if (jsonResult === null) {
      return;
    }

    storage.setItem('helpfulVotes', jsonResult);

    sendToMetrics({ path: pathname, type: 'doc', vote, comments: comment });
  };

  const handleCommentBlur = () => {
    setVote(null);
  };

  // LocalStorage is dependent on window, so we'll prevent a re-render here by waiting until document is ready
  if (!isDocumentReady) return null;

  return (
    <div className="rm-PageThumbs PageThumbs">
      <div className="PageThumbs-helpful">
        {hasVoted ? 'Thanks for voting!' : 'Did this page help you?'}
        {!hasVoted && (
          <div className="PageThumbs-cta">
            <ThumbWithDropdown
              comment={comment}
              handleVote={handleVote}
              isSelected={vote === 1}
              onCommentBlur={handleCommentBlur}
              setComment={setComment}
              submitVote={submitVote}
              title="Yes"
            />
            <ThumbWithDropdown
              comment={comment}
              handleVote={handleVote}
              isSelected={vote === 0}
              onCommentBlur={handleCommentBlur}
              setComment={setComment}
              submitVote={submitVote}
              title="No"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageThumbs;
