import type { NextPageLink } from '@readme/backend/models/page/types';

import React, { useState, useCallback } from 'react';

import type useClassy from '@core/hooks/useClassy';
import useFuzzySearch from '@core/hooks/useFuzzySearch';

import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider } from '@ui/Menu';
import PageMenuUI, { PageMenuItem } from '@ui/PageMenu';

interface Props {
  bem: ReturnType<typeof useClassy>;
  onClick: (page: NextPageLink) => void;
  pages: NextPageLink[];
}

function PageMenu({ bem, pages = [], onClick }: Props) {
  const [pageFilter, setPageFilter] = useState('');
  const [filteredPages] = useFuzzySearch(pages, pageFilter, { key: 'name', highlight: false });

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPageFilter(e.target.value);
  }, []);

  return (
    <Menu className={bem('-pageMenu')}>
      <Flex align="center" className={bem('-pageMenu-input')} gap={0}>
        <Input onChange={handleOnChange} placeholder="Find a page" prefix={<Icon name="search" />} value={pageFilter} />
      </Flex>
      <MenuDivider />
      <PageMenuUI className={bem('-pageMenu-results')}>
        {filteredPages.length ? (
          filteredPages.map((page, idx) => {
            return <PageMenuItem {...page} key={`${idx}-${page.slug}`} onClick={onClick} />;
          })
        ) : (
          <Flex align="center" className={bem('-pageMenu-results_empty')} gap="1" justify="center">
            No Results
          </Flex>
        )}
      </PageMenuUI>
    </Menu>
  );
}

export default PageMenu;
