import React from 'react';

interface Props {
  voteYes: boolean;
}

const ThumbIcon = ({ voteYes }: Props) => {
  const upOrDown = voteYes ? 'up-2' : 'down1';

  return <i className={`icon icon-thumbs-${upOrDown}`} />;
};

export default ThumbIcon;
