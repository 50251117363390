import React from 'react';

import classy from '@core/utils/classy';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';

import CommentBox from './CommentBox';
import ThumbIcon from './ThumbIcon';

interface Props {
  comment: string;
  handleVote: (vote: boolean) => void;
  isSelected: boolean;
  onCommentBlur: () => void;
  setComment: (comment: string) => void;
  submitVote: () => void;
  title: string;
}

const ThumbsWithDropdown = ({
  comment,
  handleVote,
  isSelected,
  onCommentBlur,
  setComment,
  submitVote,
  title,
}: Props) => {
  const voteType = title === 'Yes';

  return (
    <Dropdown align="top" justify="center" onBlur={onCommentBlur} sticky>
      <Button
        className={classy('PageThumbs-button', isSelected && 'PageThumbs-button_selected')}
        kind="secondary"
        onClick={() => handleVote(voteType)}
        size="sm"
        text
      >
        <ThumbIcon voteYes={voteType} />
        <span>{title}</span>
      </Button>
      <CommentBox comment={comment} setComment={setComment} submitVote={submitVote} />
    </Dropdown>
  );
};

export default ThumbsWithDropdown;
